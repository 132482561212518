import Modal from 'react-modal';
import cn from 'classnames';
import { usePopup, ButtonType, ButtonResult } from 'ui';

function App() {
    const state = usePopup();
    return <Modal
        ariaHideApp={false}
        isOpen={state.open}
        className={cn(`modal modal--${state.size}`, state.popUpClass)}
        overlayClassName="modal__overlay"
        onRequestClose={() => { state.close(ButtonResult.Cancel) }}
        shouldCloseOnOverlayClick={state.buttons.some(button => button.result === ButtonResult.Cancel)}
    >
        <div className="modal__content-wrapper">
            <h3 className="modal__title">
                {state.title}
            </h3>
            {typeof state.content === 'string'
                ? <div className="modal__content" dangerouslySetInnerHTML={{ __html: state.content }}>
                </div>
                : (typeof state.content === 'object'
                    ? <div className="modal__content">
                            <div>{state.content}</div>
                        </div>
                    : <div className="modal__content">
                        {state.content}
                    </div>)
            }

            {state.buttons.length > 0 &&
                <div className={cn("modal__footer", state.footerReverse && "flex-row-reverse")}>
                    {state.footerContent}
                    <div className="modal__buttons">
                        {state.buttons.filter(button => button.visible ?? true).map(button => {
                            switch (button.type) {
                                case ButtonType.Primary:
                                    return <a key={button.result} onClick={() => state.close(button.result)} className={cn("btn btn--primary", button.className, !(button.enabled ?? true) && "disabled")}>{button.label}</a>
                                case ButtonType.Outlined:
                                    return <a key={button.result} onClick={() => state.close(button.result)} className={cn("btn btn--outlined", button.className, !(button.enabled ?? true) && "disabled")}>{button.label}</a>
                                case ButtonType.Link:
                                    return <a key={button.result} onClick={() => state.close(button.result)} className={cn("btn btn--linked", button.className, !(button.enabled ?? true) && "disabled")}>{button.label}</a>
                            }
                        })}
                    </div>
                </div>
            }
        </div>
    </Modal>
}

export default App;
